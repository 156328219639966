<template>
  <v-card fluid>
    <v-card-text>
      <v-form ref="formProveedor">
        <v-row>
          <v-col cols="10">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre(s)*"
                  :rules="[(v) => !!v || 'Dato Requerido']"
                  v-model="$store.state.entities.cliente.names"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Apellido Paterno"
                  v-model="$store.state.entities.cliente.surname"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Apellido Materno"
                  v-model="$store.state.entities.cliente.second_surname"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                  :items="$store.state.masterusuarios.lstTipoTransaccion"
                  item-text="descripcion"
                  item-value="id"
                  label="LOCAL / EXTRANJERO *"
                  :rules="[(v) => !!v || 'Dato Requerido']"
                  v-model="$store.state.entities.cliente.id_tipotransaccion"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  :items="$store.state.itemsDocumentsPais"
                  item-text="description"
                  item-value="id"
                  label="Tipo de Documento Fiscal"
                  v-model="$store.state.entities.cliente.id_tipodocumento"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Nro De Documento"
                  v-model="$store.state.entities.cliente.nro_documento"
                  @blur="validarDocument()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  item-text="name"
                  item-value="id"
                  :items="$store.state.itemsPais"
                  label="País"
                  @change="_getState($store.state.entities.cliente.id_pais)"
                  v-model="$store.state.entities.cliente.id_pais"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  :items="$store.state.itemsState"
                  item-text="name"
                  item-value="id"
                  label="Departamento"
                  v-model="$store.state.entities.cliente.id_ciudad"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Dirección"
                  v-model="$store.state.entities.cliente.direccion"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                  :items="$store.state.itemsSex"
                  item-text="name"
                  item-value="id"
                  v-model="$store.state.entities.cliente.id_sex"
                  label="Sexo *"
                  :rules="[(v) => !!v || 'Dato Requerido']"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  type="date"
                  v-model="$store.state.entities.cliente.birthday"
                  label="Fecha de Nacimiento"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  type="number"
                  suffix="días"
                  v-model="$store.state.entities.cliente.dias_credito"
                  label="Días de Crédito para Pago"
                  :rules="[(v) => v >= 0 || 'Es necesario que ponga un número entero positivo']"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Correo Electrónico"
                  :rules="validarCorreoElectronico"
                  v-model="$store.state.entities.cliente.emailaddress"
                >
                </v-text-field>
              </v-col>              

              <v-col cols="12">
                <v-text-field
                  textarea
                  label="Comentarios/ Notas importantes"
                  v-model="$store.state.entities.cliente.notas"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2 mt-5"
                  fab
                  dark
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogTelCont = !dialogTelCont"
                  color="blue-grey darken-3"
                >
                  <v-icon dark> mdi-phone </v-icon>
                </v-btn>
              </template>
              <span>Teléfonos</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2 mt-5"
                  fab
                  dark
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  color="blue-grey darken-3"
                  @click="
                    dialogInformacionBancaria = !dialogInformacionBancaria
                  "
                >
                  <v-icon dark> mdi-bank </v-icon>
                </v-btn>
              </template>
              <span>Información Bancaria</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2 mt-5"
                  fab
                  dark
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  color="blue-grey darken-3"
                  @click="
                    dialogConveniosTarifas = !dialogConveniosTarifas
                  "
                >
                  <v-icon dark> mdi-currency-usd </v-icon>
                </v-btn>
              </template>
              <span>Convenios y Tarifas</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2 mt-5"
                  fab
                  dark
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  color="blue-grey darken-3"
                  @click="
                    dialogInformacionEntrega = !dialogInformacionEntrega
                  "
                >
                  <v-icon dark> mdi-truck </v-icon>
                </v-btn>
              </template>
              <span>Información de Entrega</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2 mt-5"
                  fab
                  dark
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  color="blue-grey darken-3"
                  @click="
                    dialogShippers = !dialogShippers
                  "
                >
                  <v-icon dark> mdi-account-group </v-icon>
                </v-btn>
              </template>
              <span>Shippers</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :loading="loading"
        class="mx-1 mb-5"
        color="success"
        @click="registrar()"
        >Registrar</v-btn
      >
      <v-btn
        :loading="loading"
        class="mx-1 mb-5"
        color="error"
        v-if="mostrarCancelar()"
        @click="
          $router.push({
            name: 'listClientes',
          })
        "
      >
        Cancelar</v-btn
      >
    </v-card-actions>
    <!-- ------------------------------------- -->
    <v-dialog
      v-model="mostrarProveedorSimilarRazonSocial"
      scrollable
      persistent
      max-width="70%"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          {{ titleVal }}
        </v-card-title>
        <v-card-text>
          <v-text-field label="Buscar" v-model="search"></v-text-field>
          <v-data-table
            :headers="headers"
            :items="$store.state.entities.lstProveedor"
            fixed-header
            class="elevation-1"
            item-key="id"
            :search="search"
          >
            <template v-slot:[`item.correlativos`]="{ item }">
              CLI-{{ item.correlativo }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                color="danger"
                @click="
                  $router.push({
                    name: 'editarCliente',
                    params: { id: item.id },
                  })
                "
                icon
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            @click="
              mostrarProveedorSimilarRazonSocial =
                !mostrarProveedorSimilarRazonSocial
            "
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="snackbar" width="33%" persistent>
      <v-card>
        <v-card-title> INFORMACIÓN IMPORTANTE </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h4>
                El <b>Tipo de Proveedor</b> afecta en la forma de organizar los
                costos en el pricing:
              </h4>

              <p class="my-0 py-0">
                Para un rol diferente a "AD | TR | AL" se considerará Cómo Costo
                Local
              </p>
              <p class="my-0 py-0">
                Para un rol igual a "AD | TR " se considerará Cómo Costo de
                Aduana
              </p>
              <p class="my-0 py-0">
                Para un rol igual a "AL" se considerará Cómo Costo de Almacén
              </p>
              <p class="my-0 py-0">
                Para un rol igual a "O" se considerará Cómo Costo de flete,
                siempre que el costo haya sido considerado como Flete.
              </p>
            </v-col>
            <v-col>
              
              <v-checkbox
                v-model="nomosrastrar"
                label="No volver a Mostrar"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="snackbar = false" text color="red" v-if="mostrarboton">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!--TELÉFONOS-->
    <v-dialog
      v-model="dialogTelCont"
      persistent
      max-width="50%"
      transition="dialog-transition"
      scrollable
    >
      <v-card>
        <v-card-title> Teléfonos y Contactos </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="10">
              <v-subheader>
                <b>Teléfonos:</b> <br />
                <v-icon v-if="!!msgTelefono" color="#FFEB3B"
                  >mdi-alert-outline</v-icon
                >
                <p class="my-0 red--text">
                  {{ msgTelefono }}
                </p>
                <v-icon v-if="!!msgTelefono" color="#FFEB3B"
                  >mdi-alert-outline</v-icon
                >
              </v-subheader>
            </v-col>
            <v-col cols="2" align-self="center">
              <v-btn color="primary" @click="agregarTelefono()" rounded small
                >Agregar Fila</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-data-table
                v-if="mostrarTablaTelefono"
                :headers="headersTel"
                :items="$store.state.entities.lstTelefono"
                item-key="index"
                :rules="[(v) => !!v || 'Dato Requerido']"
                class="elevation-5"
              >
                <template v-slot:[`item.tipotelefono`]="{ item }">
                  <v-autocomplete
                    :items="$store.state.masterusuarios.lstTipoTelefono"
                    item-text="descripcion"
                    item-value="id"
                    label="Tipo de Teléfono"
                    v-model="item.id_tipotelefono"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                    :error-messages="item.errorTipoTelf"
                    @change="validarFormularioTelefonoContacto"
                  >
                  </v-autocomplete>
                </template>
                <template v-slot:[`item.nro_telefono`]="{ item }">
                  <v-text-field
                    v-model="item.telefono"
                    prefix="+"
                    :rules="[
                      (v) => !!v || 'Dato Requerido',
                      (v) => /^\d+$/.test(v) || 'Formato de número incorrecto',
                    ]"
                    :error-messages="item.errorTelefono"
                    v-on:blur="validarFormularioTelefonoContacto()"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.action`]="{ item, index }">
                  <v-btn icon color="red" @click="eliminarTelefono(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>

            <!-- -------------------------------- -->
            <v-col cols="10">
              <v-subheader><b>Contactos</b></v-subheader>
            </v-col>
            <v-col cols="2" align-self="center">
              <v-btn color="primary" @click="agregarContacto()" rounded small
                >Agregar Fila</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headersContact"
                :items="$store.state.entities.lstContactos"
                item-key="index"
                class="elevation-5"
                v-if="mostrarTablaTelefono"
              >
                <template v-slot:[`item.nombre`]="{ item }">
                  <v-text-field
                    v-model="item.nombre"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                    :error-messages="item.errornombre"
                    v-on:blur="validarFormularioTelefonoContacto()"
                  ></v-text-field>
                </template>

                <template v-slot:[`item.tipotelefono`]="{ item }">
                  <v-autocomplete
                    :items="$store.state.masterusuarios.lstTipoTelefono"
                    item-text="descripcion"
                    item-value="id"
                    label="Tipo de Teléfono"
                    v-model="item.id_tipotelefono"
                    :rules="[(v) => !!v || 'Dato Requerido']"
                    :error-messages="item.errorTipoTelf"
                    @change="validarFormularioTelefonoContacto"
                  >
                  </v-autocomplete>
                </template>
                <template v-slot:[`item.nro_telefono`]="{ item }">
                  <v-text-field
                    v-model="item.telefono"
                    prefix="+"
                    :rules="[
                      (v) => !!v || 'Dato Requerido',
                      (v) => /^\d+$/.test(v) || 'Formato de número incorrecto',
                    ]"
                    :error-messages="item.errorTelefono"
                    v-on:blur="validarFormularioTelefonoContacto()"
                  ></v-text-field>
                </template>

                <template v-slot:[`item.action`]="{ item, index }">
                  <v-btn icon color="red" @click="eliminarContacto(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-1"
            color="success"
            @click="aceptarFormularioTelefonoContacto()"
            >Aceptar</v-btn
          >
          <v-btn class="mx-1" color="red" dark @click="cerrarTelContacto()"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--INFORMACIÓN BANCARIA-->
    <v-dialog
      v-model="dialogInformacionBancaria"
      scrollable
      persistent
      max-width="70%"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Información Bancaría </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2" align-self="center">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="agregarCuentaBancaria()"
                rounded
                small
              >
                Agregar Fila
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="
                  isInternacional()
                    ? headersInformacionBancaria2
                    : headersInformacionBancaria1
                "
                :items="$store.state.entities.lstInformacionBancaria"
                class="elevation-5"
                item-key="index"
                loading="true"
              >
                <template v-slot:[`item.nrocuenta`]="{ item }">
                  <v-text-field
                    v-model="item.nro_cuenta"
                    :error-messages="item.errornro_cuenta"
                    id="id"
                    placeholder="Nro Cuenta"
                    v-on:blur="validarFormularioInformacionBancaria()"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.cci`]="{ item }">
                  <v-text-field
                    v-model="item.cci"
                    :error-messages="item.errorcci"
                    id="id"
                    placeholder="CCI"
                    v-on:blur="validarFormularioInformacionBancaria()"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.banco`]="{ item }">
                  <v-autocomplete
                    :items="$store.state.bank.list"
                    v-model="item.id_banco"
                    :error-messages="item.errorid_banco"
                    item-text="name"
                    placeholder="Bancos"
                    item-value="id"
                    @change="validarFormularioInformacionBancaria()"
                  >
                  </v-autocomplete>
                </template>
                <template v-slot:[`item.moneda`]="{ item }">
                  <v-autocomplete
                    :items="$store.state.coins.itemsCoinsList"
                    v-model="item.id_coins"
                    :error-messages="item.errorid_coins"
                    item-text="acronym"
                    placeholder="Monedas"
                    @change="validarFormularioInformacionBancaria()"
                    item-value="id"
                  >
                  </v-autocomplete>
                </template>
                <template v-slot:[`item.swift`]="{ item }">
                  <v-text-field
                    placeholder="Nro swift"
                    v-model="item.nro_swift"
                    :error-messages="item.errornro_swift"
                    id="id"
                    v-on:blur="validarFormularioInformacionBancaria()"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.intermediario`]="{ item }">
                  <v-autocomplete
                    :items="$store.state.bank.list"
                    v-model="item.id_intermediario"
                    :error-messages="item.errorid_intermediario"
                    item-text="name"
                    placeholder="Bancos"
                    @change="validarFormularioInformacionBancaria()"
                    item-value="id"
                  >
                  </v-autocomplete>
                </template>
                <template v-slot:[`item.cuenta_intermediario`]="{ item }">
                  <v-text-field
                    placeholder="Cuenta Intermediario"
                    v-model="item.nro_cuenta_intermediario"
                    :error-messages="item.errornro_cuenta_intermediario"
                    id="id"
                    v-on:blur="validarFormularioInformacionBancaria()"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.action`]="{ item, index }">
                  <v-btn
                    color="red"
                    icon
                    @click="elimiarInformacionBancaria(index)"
                  >
                    <v-icon> mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-1"
            color="success"
            @click="aceptarFormularioInformacionBancaria()"
            >Aceptar</v-btn
          >
          <v-btn
            class="mx-1"
            color="red"
            dark
            @click="cerrarInformacionBancaria()"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--CONVENIOS Y TARIFAS-->
    <v-dialog
      v-model="dialogConveniosTarifas"
      scrollable
      persistent
      max-width="70%"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Convenios y Tarifas </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="10">
              <v-subheader>
                <b>Convenios:</b>
              </v-subheader>
            </v-col>
            <v-col cols="2" align-self="center">
              <v-btn color="primary" @click="agregarConvenio()" rounded small
                >AGREGAR CONVENIO</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-form ref="formConvenios">
                <v-data-table
                  :headers="headersConvenios"
                  :items="$store.state.entities.lstConvenios"
                  class="elevation-5"
                  item-key="index"
                > 

                  <template v-slot:[`item.fecha`]="{ item }">
                    <v-text-field
                      type="date"
                      v-model="item.fecha"
                      :rules="[(v) => !!v || 'Dato Requerido']"
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.dias_credito`]="{ item }">
                    <v-text-field
                      type="number"
                      suffix="días"
                      v-model="item.dias_credito"
                      :rules="[(v) => v >= 0 || 'Es necesario que ponga un número entero positivo']"
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.email_soporte`]="{ item }">
                    <v-text-field
                      v-model="item.email_soporte"
                      :rules="[
                        (v) => !!v || 'Dato Requerido',
                        (v) => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Ingresa un email válido'
                      ]"
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.action`]="{ item, index }">
                    <v-btn icon color="red" @click="eliminarConvenio(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>   
                
                </v-data-table>
              </v-form>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10">
              <v-subheader>
                <b>Tarifas:</b>
              </v-subheader>
            </v-col>
            <v-col cols="2" align-self="center">
              <v-btn color="primary" @click="agregarTarifa()" rounded small
                >AGREGAR TARIFA</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-form ref="formTarifas">
                <v-data-table
                  :headers="headersTarifas"
                  :items="$store.state.entities.lstTarifas"
                  class="elevation-5"
                  item-key="index"
                > 

                  <template v-slot:[`item.fecha`]="{ item }">
                    <v-text-field
                      type="date"
                      v-model="item.fecha"
                      :rules="[(v) => !!v || 'Dato Requerido']"
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.codigo`]="{ item }">
                    <v-text-field
                      v-model="item.codigo"
                      :rules="[(v) => !!v || 'Dato Requerido']"
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.email_soporte`]="{ item }">
                    <v-text-field
                      v-model="item.email_soporte"
                      :rules="[
                        (v) => !!v || 'Dato Requerido',
                        (v) => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Ingresa un email válido'
                      ]"
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.tarifa`]="{ item }">
                    <v-text-field
                      v-model="item.tarifa"
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.action`]="{ item, index }">
                    <v-btn icon color="red" @click="eliminarTarifa(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>   
                
                </v-data-table>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-1"
            color="success"
            @click="aceptarFormularioConveniosTarifas()"
            >Aceptar</v-btn
          >
          <v-btn
            class="mx-1"
            color="red"
            dark
            @click="cerrarDialogConveniosTarifas()"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--INFORMACIÓN DE ENTREGA-->
    <v-dialog
      v-model="dialogInformacionEntrega"
      scrollable
      persistent
      max-width="70%"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>Información de Entrega</v-card-title>
        <v-card-title class="text-subtitle-1 pt-0">Datos para rótulos en la entrega del producto:</v-card-title>
        <v-card-text>
          <v-form ref="formInformacionEntrega">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  hide-details
                  label="Destinatario"
                  v-model="$store.state.entities.informacionEntrega.destinatario"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  hide-details
                  label="DNI O RUC"
                  v-model="$store.state.entities.informacionEntrega.dni_destinatario"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  hide-details
                  label="Persona que recoge"
                  v-model="$store.state.entities.informacionEntrega.receptor"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  hide-details
                  label="DNI"
                  v-model="$store.state.entities.informacionEntrega.dni_receptor"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  :items="$store.state.itemsState"
                  item-text="name"
                  item-value="id"
                  hide-details
                  label="Departamento"
                  v-model="$store.state.entities.informacionEntrega.id_departamento"
                  @change="_getCity($store.state.entities.informacionEntrega.id_departamento)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  :items="$store.state.itemsCity"
                  item-text="name"
                  item-value="id"
                  hide-details
                  label="Provincia"
                  v-model="$store.state.entities.informacionEntrega.id_provincia"
                  @change="_getTown($store.state.entities.informacionEntrega.id_provincia)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  :items="$store.state.itemsTown"
                  item-text="name"
                  item-value="id"
                  hide-details
                  label="Distrito"
                  v-model="$store.state.entities.informacionEntrega.id_distrito"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  hide-details
                  label="Agencia"
                  v-model="$store.state.entities.informacionEntrega.agencia"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  hide-details
                  label="Celular"
                  v-model="$store.state.entities.informacionEntrega.celular_agencia"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  hide-details
                  label="Cliente"
                  v-model="$store.state.entities.cliente.names"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <div class="d-flex align-center">
                  Estado
                  <v-switch
                    hide-details
                    v-model="$store.state.entities.informacionEntrega.status"
                    class="mt-0 ml-2"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-1"
            color="primary"
            dark
            rounded
            >Imprimir</v-btn
          >
          <v-btn
            class="mx-1"
            color="success"
            dark
            rounded
            @click="cerrarDialogInformacionEntrega"
            >Aceptar</v-btn
          >
          <v-btn
            class="mx-1"
            color="red"
            dark
            rounded
            @click="cerrarDialogInformacionEntrega"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--SHIPPERS-->
    <v-dialog
      v-model="dialogShippers"
      scrollable
      persistent
      max-width="70%"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Shippers </v-card-title>
        <v-card-text>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="2" class="text-right">
              <v-btn color="primary" @click="agregarShipper()" rounded small
                >AGREGAR SHIIPER</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-form ref="formShippers">
                <v-data-table
                  :headers="headersShippers"
                  :items="$store.state.entities.lstShippers"
                  class="elevation-5"
                  item-key="index"
                > 

                  <template v-slot:[`item.codigo`]="{ item }">
                    <v-text-field
                      v-model="item.codigo"
                      :rules="[(v) => !!v || 'Dato Requerido']"
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.shipper`]="{ item }">
                    <v-text-field
                      v-model="item.shipper"
                      :rules="[(v) => !!v || 'Dato Requerido']"
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.action`]="{ item, index }">
                    <v-btn icon color="red" @click="eliminarShipper(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>   
                
                </v-data-table>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-1"
            color="success"
            @click="aceptarFormularioShippers()"
            >Aceptar</v-btn
          >
          <v-btn
            class="mx-1"
            color="red"
            dark
            @click="cerrarDialogShippers()"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading:false,
      titleVal: "",
      defaultTipoTransaccion: 56,
      headersTel: [
        { text: "Tipo de Teléfono", value: "tipotelefono", width: "30%" },
        { text: "Nro Teléfono", value: "nro_telefono", width: "50%" },
        { text: "Acciones", value: "action", width: "20%" },
      ],
      headersContact: [
        { text: "Nombres", value: "nombre" },
        { text: "Tipo de Teléfono", value: "tipotelefono" },
        { text: "Nro Teléfono", value: "nro_telefono" },
        { text: "Acciones", value: "action" },
      ],
      headersInformacionBancaria1: [
        { text: "Nro de Cuenta", value: "nrocuenta" },
        { text: "CCI", value: "cci" },
        { text: "Banco", value: "banco" },
        { text: "Moneda", value: "moneda" },
        { text: "Acciones", value: "action" },
      ],
      headersInformacionBancaria2: [
        { text: "Nro de Cuenta", value: "nrocuenta" },
        { text: "CCI", value: "cci" },
        { text: "Banco", value: "banco" },
        { text: "Moneda", value: "moneda" },

        { text: "Nro. de Swift", value: "swift" },
        { text: "Banco Intermediario", value: "intermediario" },
        {
          text: "Nro Cuenta de Banco Intermediario",
          value: "cuenta_intermediario",
        },

        { text: "Acciones", value: "action" },
      ],
      headersConvenios: [
        { text: "Fecha", value: "fecha", width: "20%" },
        { text: "Días para Crédito", value: "dias_credito", width: "30%" },
        { text: "Email Soporte", value: "email_soporte", width: "40%" },
        { text: "Acciones", value: "action", width: "10%" },
      ],
      headersTarifas: [
        { text: "Fecha", value: "fecha", width: "20%" },
        { text: "Código", value: "codigo", width: "20%" },
        { text: "Email Soporte", value: "email_soporte", width: "30%" },
        { text: "Tarifa", value: "tarifa", width: "20%" },
        { text: "Acciones", value: "action", width: "10%" },
      ],
      headersShippers: [
        { text: "Código", value: "codigo", width: "30%" },
        { text: "Shipper", value: "shipper", width: "60%" },
        { text: "Acciones", value: "action", width: "10%" },
      ],
      search: "",

      msgTelefono: "",
      dialogTelCont: false,
      dialogInformacionBancaria: false,
      dialogConveniosTarifas: false,
      dialogInformacionEntrega: false,
      dialogShippers: false,
      snackbar: false,
      nomosrastrar: false,
      mostrarboton: false,
      mostrarProveedorSimilarRazonSocial: false,
      mostrarTablaTelefono: true,
      headers: [
        { text: "Correlativo", value: "correlativos" },
        { text: "Documento", value: "document" },
        { text: "Razón Social", value: "nombrecompleto" },
        { text: "Acción", value: "actions" },
      ],
    };
  },
  computed: {
    validarCorreoElectronico() {
      return [
        (v) => {
          if (v !== null && v !== "")
          {
            if (!/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v)) return 'Ingresa un correo electrónico válido';
          }
          
          return true;
        }
      ];
    }
  },
  methods: {
    ...mapActions([
      "getListProveedor",
      "_getDocumentsPais",
      "cargarMasterDetalleTipoProveedor",
      "cargarMasterDetalleTipoTransaccion",
      "_getRole",
      "getValidaRazonSocial",
      "cargarTipoTelefono",
      "getCoinsListCargar",
      "getBanksListCargar",
      "guardarCliente",
      "getValidaDocumentCliente",
      "_getSex",
      "_getPais",
      "_getState",
      "_getCity",
      "_getTown",
    ]),
    mostrarNotificacion() {
      if (!this.nomosrastrar) {
        this.mostrarboton = false;
        this.snackbar = true;
        setTimeout(() => {
          this.mostrarboton = true;
        }, 5000);
        setTimeout(() => {
          this.snackbar = false;
        }, 8000);
      }
    },
    async validarRazonSocial() {
      this.titleVal =
        "Se han encontrado los siguientes registros con un Nombre Comercial o Razón Social similar al ingresado";
      if (this.$store.state.entities.cliente.bussiness_name) {
        this.$store.state.spiner = true;
        await this.getValidaRazonSocial();
        if (this.$store.state.entities.lstProveedor.length > 0) {
          this.mostrarProveedorSimilarRazonSocial = true;
        }
        this.$store.state.spiner = false;
      }
    },
    async validarDocument() {
      this.titleVal = this.titleVal =
        "Se han encontrado los siguientes registros con el Tipo de Documento y Documento ingresado";

      if (this.$store.state.entities.cliente.nro_documento) {
        this.$store.state.spiner = true;
        await this.getValidaDocumentCliente();
        if (this.$store.state.entities.lstProveedor.length > 0) {
          this.mostrarProveedorSimilarRazonSocial = true;
        }
        this.$store.state.spiner = false;
      }
    },
    isNaviera() {
      let code = [9002, 9021];
      return this.$store.state.itemsDataRoleList.some(
        (v) =>
          code.includes(v.code) &&
          v.id == this.$store.state.entities.cliente.id_role
      );
    },
    isAlmacen() {
      let code = [9007, 9022];
      return this.$store.state.itemsDataRoleList.some(
        (v) =>
          code.includes(v.code) &&
          v.id == this.$store.state.entities.cliente.id_role
      );
    },
    agregarTelefono() {
      this.$store.state.entities.lstTelefono.push({
        id_tipotelefono: "",
        telefono: "",
      });
      this.msgTelefono = "";
    },
    eliminarTelefono(index) {
      this.$store.state.entities.lstTelefono.splice(index, 1);
    },
    agregarContacto() {
      this.$store.state.entities.lstContactos.push({
        nombre: "",
        id_tipotelefono: "",
        telefono: "",
      });
    },
    eliminarContacto(index) {
      this.$store.state.entities.lstContactos.splice(index, 1);
    },
    aceptarFormularioTelefonoContacto() {
      let validacion = true;
      if (this.$store.state.entities.lstTelefono.length > 0) {
        this.msgTelefono = "";
        validacion = this.validarFormularioTelefonoContacto();
      } else {
        validacion = false;
        this.msgTelefono =
          "Para poder registrar un cliente, es necesario al menos un teléfono";
      }
      if (validacion) {
        this.dialogTelCont = !this.dialogTelCont;
      }
    },
    validarFormularioTelefonoContacto() {
      let val = true;

      this.$store.state.entities.lstTelefono.forEach((element) => {
        if (!element.id_tipotelefono) {
          element.errorTipoTelf = "Dato Requierdo.";
          val = false;
        } else {
          element.errorTipoTelf = "";
        }
        if (!element.telefono) {
          element.errorTelefono = "Dato Requierdos.";
          val = false;
        } else if (!/^\d+$/s.test(element.telefono)) {
          element.errorTelefono = "Formato de número incorrecto";
          val = false;
        } else {
          element.errorTelefono = "";
        }
      });

      this.$store.state.entities.lstContactos.forEach((element) => {
        if (!element.nombre) {
          element.errornombre = "Dato Requierdo.";
          val = false;
        } else {
          element.errornombre = "";
        }
        if (!element.id_tipotelefono) {
          element.errorTipoTelf = "Dato Requierdo.";
          val = false;
        } else {
          element.errorTipoTelf = "";
        }
        if (!element.telefono) {
          element.errorTelefono = "Dato Requierdos.";
          val = false;
        } else if (!/^\d+$/s.test(element.telefono)) {
          element.errorTelefono = "Formato de número incorrecto";
          val = false;
        } else {
          element.errorTelefono = "";
        }
      });

      this.mostrarTablaTelefono = !this.mostrarTablaTelefono;
      this.mostrarTablaTelefono = !this.mostrarTablaTelefono;
      return val;
    },
    cerrarTelContacto() {
      let validacion = this.validarFormularioTelefonoContacto();
      if (validacion) {
        this.dialogTelCont = !this.dialogTelCont;
      }
    },
    isInternacional() {
      let val = this.$store.state.masterusuarios.lstTipoTransaccion.some(
        (v) =>
          v.codigo == "02" &&
          v.id == this.$store.state.entities.cliente.id_tipotransaccion
      );
      return val;
    },
    agregarCuentaBancaria() {
      this.$store.state.entities.lstInformacionBancaria.push({
        nro_cuenta: "",
        cci: "",
        id_banco: "",
        id_coins: "",
        nro_swift: "",
        id_intermediario: "",
        nro_cuenta_intermediario: "",
      });
    },
    elimiarInformacionBancaria(index) {
      this.$store.state.entities.lstInformacionBancaria.splice(index, 1);
    },
    aceptarFormularioInformacionBancaria() {
      let val = this.validarFormularioInformacionBancaria();
      if (val) {
        this.dialogInformacionBancaria = !this.dialogInformacionBancaria;
      }
    },
    validarFormularioInformacionBancaria() {
      let val = true;
      this.$store.state.entities.lstInformacionBancaria.forEach((element) => {
        if (!element.nro_cuenta) {
          element.errornro_cuenta = "Datos Requerido.";
          val = false;
        } else {
          element.errornro_cuenta = "";
        }
        if (!element.cci) {
          element.errorcci = "Datos Requerido.";
          val = false;
        } else {
          element.errorcci = "";
        }
        if (!element.id_banco) {
          element.errorid_banco = "Datos Requerido.";
          val = false;
        } else {
          element.errorid_banco = "";
        }
        if (!element.id_coins) {
          element.errorid_coins = "Datos Requerido.";
          val = false;
        } else {
          element.errorid_coins = "";
        }
        if (!element.nro_swift && this.isInternacional()) {
          element.errornro_swift = "Datos Requerido.";
          val = false;
        } else {
          element.errornro_swift = "";
        }
        if (!element.id_intermediario && this.isInternacional()) {
          element.errorid_intermediario = "Datos Requerido.";
          val = false;
        } else {
          element.errorid_intermediario = "";
        }
        if (!element.nro_cuenta_intermediario && this.isInternacional()) {
          element.errornro_cuenta_intermediario = "Datos Requerido.";
          val = false;
        } else {
          element.errornro_cuenta_intermediario = "";
        }
      });
      this.dialogInformacionBancaria = !this.dialogInformacionBancaria;
      this.dialogInformacionBancaria = !this.dialogInformacionBancaria;
      return val;
    },
    cerrarInformacionBancaria() {
      if (this.validarFormularioInformacionBancaria()) {
        this.dialogInformacionBancaria = !this.dialogInformacionBancaria;
      }
    },
    cerrarDialogConveniosTarifas() {
      this.aceptarFormularioConveniosTarifas();
      //this.dialogConveniosTarifas = !this.dialogConveniosTarifas;
    },
    agregarConvenio() {
      this.$store.state.entities.lstConvenios.push({
        fecha: "",
        dias_credito: 0,
        email_soporte: "",
      });
    },
    eliminarConvenio(index) {
      this.$store.state.entities.lstConvenios.splice(index, 1);
    },
    agregarTarifa() {
      this.$store.state.entities.lstTarifas.push({
        fecha: "",
        codigo: "",
        email_soporte: "",
        tarifa: 0,
      });
    },
    eliminarTarifa(index) {
      this.$store.state.entities.lstTarifas.splice(index, 1);
    },
    aceptarFormularioConveniosTarifas() {
      let ok1 = true;
      if (this.$store.state.entities.lstConvenios.length > 0) 
      {
        ok1 = false;
        if (this.$refs.formConvenios.validate()) 
        {
          ok1 = true;
        }
      } 
      let ok2 = true;
      if (this.$store.state.entities.lstTarifas.length > 0) 
      {
        ok2 = false;
        if (this.$refs.formTarifas.validate()) 
        {
          ok2 = true;
        }
      }  

      if (ok1 && ok2)
      {
        this.dialogConveniosTarifas = !this.dialogConveniosTarifas;
      }      
    },
    async registrar() {
      if (this.$refs.formProveedor.validate()) {
        if (this.$store.state.entities.lstTelefono.length > 0) {
          this.loading = true;
          await this.guardarCliente();
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: "Aviso importante",
            text: "Es necesario guardar al menos un teléfono.",
            confirmButtonText: "Entendido",
            showCancelButton: false,
            allowOutsideClick: false,
          }).then((res) => {
            if (res.isConfirmed) {
              this.dialogTelCont = true;
            }
          });
        }
      }
    },
    mostrarCancelar() {
      return this.$router.currentRoute.name === "nuevoClientes";
    },
    cerrarDialogInformacionEntrega() {
      this.dialogInformacionEntrega = !this.dialogInformacionEntrega;
    },
    aceptarFormularioShippers() {
      let ok = true;
      if (this.$store.state.entities.lstShippers.length > 0) 
      {
        ok = false;
        if (this.$refs.formShippers.validate()) 
        {
          ok = true;
        }
      }  

      if (ok) 
      {
        this.dialogShippers = !this.dialogShippers;
      }      
    },
    cerrarDialogShippers() {
      this.aceptarFormularioShippers();
    },
    agregarShipper() {
      this.$store.state.entities.lstShippers.push({
        codigo: "",
        shipper: "",
      });
    },
    eliminarShipper(index) {
      this.$store.state.entities.lstShippers.splice(index, 1);
    },
  },
  async mounted() {
    this.$store.state.spiner = true;
    this.$refs.formProveedor.reset();
    await this._getDocumentsPais();
    await this.cargarMasterDetalleTipoProveedor();
    await this._getPais();
    await this.cargarMasterDetalleTipoTransaccion();
    await this.cargarTipoTelefono();
    await this._getRole();
    await this.getCoinsListCargar();
    await this.getBanksListCargar();
    await this._getSex();
    this.$store.state.spiner = false;

    this.$store.state.entities.lstTelefono = [];
    this.$store.state.entities.lstContactos = [];
    this.$store.state.entities.lstInformacionBancaria = [];
    this.$store.state.entities.lstConvenios = [];
    this.$store.state.entities.lstTarifas = [];
    this.$store.state.entities.informacionEntrega = { status: true };
    this.$store.state.entities.lstShippers = [];
    this.$store.state.entities.cliente.id_tipotransaccion = "56";
    this.$store.state.entities.cliente.dias_credito = "0";
    this.$store.state.entities.cliente.dias_sobreestadia = "0";
    this.$store.state.entities.cliente.dias_almacenaje = "0";
    this.snackbar = true;
    setTimeout(() => {
      this.mostrarboton = true;
    }, 5000);
    setTimeout(() => {
      this.snackbar = false;
    }, 8000);
  },
  afterMount() {},
};
</script>

<style></style>
